import Logo from "./Logo";
import {MenuBox, MenuBoxes} from "./MenuBoxes";
import {MenuFooter} from "../FirstPageNew";
import {useParams} from "react-router-dom";

const NiceTime = () => {
    const language = useParams().language as string;

    const title: { [key: string]: string } = {
        'pol':'Ile masz czasu, by wysłuchać historii tego miejsca?',
        'eng':'How much time do you have to listen to the history of this place?',
        'heb': 'כמה זמן יש לך?'
    };

    const timeSuffix: { [key: string]: string } = {
        'pol':' minut',
        'eng':' minutes',
        'heb': ''
    };

    return <div className="BasicPage">
        <div><Logo></Logo></div>
        <MenuBoxes>
            <div>{ title[language] }</div>
            <MenuBox link={`/mila/${language}/5`}>5{ timeSuffix[language] }</MenuBox>
            <MenuBox link={`/mila/${language}/15`}>15{ timeSuffix[language] }</MenuBox>
        </MenuBoxes>
        <MenuFooter/>
    </div>
}

export {NiceTime}
