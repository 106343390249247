import { iPoint } from "../components/Map";
import { getFilename, getJSONfile } from "./dataaccess";

interface iGuidePoint extends iPoint {
    id:string,
    mp3?:string,
    photo?:string | string[] | {picture:string, description?:string}[]
}

async function getGameLocalisations(gameName:string, lang:string): Promise<iGuidePoint[] | null> {
    const filename = await getFilename(gameName,lang,'points.json')
    console.log(filename)
    if(!filename) return null;
    return getJSONfile(filename)
}


export {getGameLocalisations}
export type {iGuidePoint}