import Logo from "./Logo";
import {MenuBox, MenuBoxes} from "./MenuBoxes";
import {MenuFooter} from "../FirstPageNew";
import {useParams} from "react-router-dom";
import {FullscreenViever, useClickToFullscreen, useFullscreen} from "./FullscreenPicture";
import React from "react";
import {GuideAudioPlayer} from "./GuidePanel";

const Nice = () => {
    const language = useParams().language as string;
    const time = Number(useParams().time);
    const audioUrl = `nice/${language}-${time}.mp3`;

    const [_,setFullscreen] = useFullscreen();
    const photos: Array<string> = [];

    for(let i= 1; i < 30; i++) {
        if (i !== 16)
            photos.push(`nice/${i.toString().padStart(2, '0')}.jpg`);
    }

    return <div className="BasicPage">
        <FullscreenViever></FullscreenViever>
        <div><Logo></Logo></div>
        <div className="GuidePointCover">
            <div className="PhotoList">
                {
                    photos.map(photo => <img src={photo} key={photo}
                                             onClick={() => setFullscreen({src: photo, align: "center"})}/>
                    )}
            </div>
            <GuideAudioPlayer mp3={audioUrl}></GuideAudioPlayer>
        </div>
        <MenuFooter/>
    </div>
}

export {Nice}
