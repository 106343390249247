import { useState } from "react"
import { useInventory } from "../utils/inventory"
import { usePhotoInventory } from "../utils/photoinventory"
import { useConfig } from "../utils/useconfig"
import { useGeo } from "../utils/usegeo"
import { useInitialData } from "../utils/useinitialdata"
import { useFullscreen } from "./FullscreenPicture"
import { InstallationModal } from "./InstallationModal"
import { GeolocationNeededModal, OneButtonModal, TextModal } from "./Modal"
import { Photoicon } from "./Photoicon"


const isLoadedFromHomescreen = () => window.matchMedia('(display-mode: standalone)').matches
const isIOS = () => navigator.userAgent.match(/iPhone|iPad/i) !== null
const isAndroid = () => navigator.userAgent.match(/Android/i) !== null




function IntroModalSequence(){
    const [initialData,setInitialData] = useInitialData(store=>store)
    const [inventoryIsEmpty, setInventory] = useInventory(store => store.length == 0)
    const [photoInventoryIsEmpty] = usePhotoInventory(store => store.length == 0)
    const isLaunchedFromHomescreen = launchedFromHomeScreen()
    const [skipPhotoIntro, setSkipPhotoIntro] = useState(false)
    const [skipInstallModal, setSkipInstallModal] = useState(false)
    const [_,setFullscreen] = useFullscreen()
    const [config] = useConfig()
  
    const {geo} = useGeo()
    const shouldShowInstallationModal = () =>  !isLoadedFromHomescreen() && isIOS() && !skipInstallModal
    
    try {
      if(initialData.firstVisit && config?.intro_movie) {
        setInitialData({type:"FIRST_VISIT"})
        setFullscreen({
          src:config.intro_movie,
          overlayText: config?.intro_text ?? "", 
          noAutoClose:true,
          overlayOnEnd:true,
        })
        return null;
      } 
    } catch(err){
      console.log(err)
    }
  
   

    if(!initialData.avatar && config?.avatars?.length) return <AvatarChoiceModal avatars={config.avatars} onChoice={(avatar:string)=>setInitialData({type:"AVATAR",avatar})} />
  
    if(inventoryIsEmpty) return <InventoryInfoModal onClick={()=>setInventory({type:"init",item:config?.first_item})} />
    if(photoInventoryIsEmpty && !skipPhotoIntro)return <PhotoInventoryInfoModal onClick={()=>setSkipPhotoIntro(true)} />
  
  
    if(geo.state === 'UNKNOWN' || geo.watcherId === undefined)return <GeolocationNeededModal />

    if(shouldShowInstallationModal()) return <InstallationModal onClick={()=>setSkipInstallModal(true)}></InstallationModal>

  
    return null;
  }
  
  function AvatarChoiceModal({avatars,onChoice}:{avatars:string[],onChoice:(avatar:string)=>void}){
    const [config] = useConfig()

    return <OneButtonModal title={config?.intro_avatar_selector_title}>
      {
        avatars.map(avatar => <div key={avatar} className='avatarChoiceItem' onClick={()=>onChoice(avatar)}><img src={avatar}></img></div>)
      }
    </OneButtonModal>
  }
  
  function InventoryInfoModal({onClick}:{onClick:()=>void}){
    const [config] = useConfig()
    
    return <TextModal title ={config?.intro_first_task?.title} buttonTitle={config?.intro_first_task?.button} buttonClick={onClick}>
      {config?.intro_first_task?.text}
    </TextModal>
  }
  
  function PhotoInventoryInfoModal({onClick}:{onClick:()=>void}){
    const [config] = useConfig()
    let text = config?.intro_photo_task?.text?.split('###ICON###') ?? []
    if(text.length == 2){ //todo: ugly hack!
        text = [text[0],<Photoicon />,text[1]]
    }

    return <TextModal title ={config?.intro_photo_task?.title} buttonTitle={config?.intro_photo_task?.button} buttonClick={onClick}>
     {text}
    </TextModal>
  }
  
  
  function launchedFromHomeScreen(){
    return false;
  }

  export {IntroModalSequence}