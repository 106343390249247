import { OneButtonModal } from "./Modal";
import iosmenuicon from "../assets/iosmenuicon.png"


const icon = <img src={iosmenuicon} style={{width:"1.2em"}} />

function InstallationModal({onClick}:{onClick:()=>void}){

    return <OneButtonModal outsideClick={onClick} title="Dodaj aplikację">Dodaj aplikację MGW do ekranu głównego, kliknij {icon} i wybierz "Add to Home Screen" / "Dodaj do ekranu głównego"</OneButtonModal>
}

export {InstallationModal}