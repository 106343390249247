import { ReactNode } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../App.css';


const MenuBoxes = ({children}:{children:ReactNode}) =>  <div className='menuBoxes'>{children}</div>

const MenuBox = ({link,children,onClick }:{link?:string,children?:ReactNode,onClick?:()=>void}) => {
    const navigate = useNavigate()
    const clickHandler = link ? ()=>navigate(link) : onClick 
    return <div className='menuBox' onClick={clickHandler}>{children}</div>
}

const MenuBoxContainer = ({children}:{children:ReactNode}) =>  <div className='menuBoxContainer'>{children}</div>

const MenuBoxRow = ({children}:{children:ReactNode}) =>  <div className='menuBoxRow'>{children}</div>



export {MenuBoxes, MenuBox, MenuBoxContainer,MenuBoxRow }