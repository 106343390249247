import { ReactNode, useRef, useState } from "react"
import { AvailableLangs } from "../utils/dataaccess"
import { useGeo } from "../utils/usegeo"

function GeolocationNeededModal({onClick,lang="PL"}:{onClick?:()=>void,lang?:AvailableLangs}){

  const messages = {
    "PL":"Do prawidłowego działania potrzebujemy dostęp do informacji o Twojej lokalizacji",
    "EN":"We need geolocalisation data to show your location on the map"
  }

  const labels = {
    "next":{
      "PL":"Dalej",
      "EN":"Next"
    },
    "checking":{
      "PL":"Sprawdzam",
      "EN":"Checking"
    },
    "title":{
      "PL":"Geolokalizacja",
      "EN":"Geolocalisation"
    }
  }

    const [buttonTitle, setButtonTitle] = useState(labels.next[lang])
    const {geo, setGeo, startGeo} = useGeo()
  
    const checkGeoAccess = () => {
      setButtonTitle(labels.checking[lang])
      startGeo()
      onClick && onClick()
    }
  


    return <OneButtonModal title ={labels.title[lang]} buttonTitle={buttonTitle} buttonClick={checkGeoAccess}>
      {messages[lang]}
    </OneButtonModal>
  }
  

function TextModal({children,title,buttonTitle,buttonClick,outsideClick}:{
  children:ReactNode,
  title?:string,
  buttonTitle?:string,
  buttonClick?:()=>void,
  outsideClick?:()=>void
}){
  return <OneButtonModal title={title} buttonTitle={buttonTitle} buttonClick={buttonClick} outsideClick={outsideClick}>
    <div className="textContent">{children}</div>
</OneButtonModal>
}

function OneButtonModal({children,title,buttonTitle,buttonClick,outsideClick}:{
    children:ReactNode,
    title?:string,
    buttonTitle?:string,
    buttonClick?:()=>void,
    outsideClick?:()=>void
  }){
    return <Modal onOutsideClick={outsideClick}>
      {title ? <h2>{title}</h2> : null }
      <div>{children}</div>
      {
        buttonTitle ? <div className='Buttons'><RedButton text={buttonTitle} onClick={buttonClick ?? (()=>{})} /></div> : null
      }
    </Modal>
  }
  
  function Modal({children, onOutsideClick}:{children:ReactNode, onOutsideClick?:()=>void}){

    const ref = useRef(null)
    const onPanelClick = (ev:any) => {
      ev.target === ref.current && onOutsideClick && onOutsideClick()
    }

    return <div ref={ref} className='ModalPanel' onClick={onPanelClick}>
      <div className='ModalDialogue'>
        <div className='Content'>
         {children}
        </div>
      </div>
    </div>
  }
  
  
  const RedButton = ({text,onClick}:{text:string,onClick:()=>void}) => <JustButton onClick={onClick}>{text}</JustButton>
  const GrayButton = ({text,onClick}:{text:string,onClick:()=>void}) => <JustButton onClick={onClick} className='GrayButton'>{text}</JustButton>
  const JustButton = ({children,onClick,className="RedButton"}:{className?:string,children:ReactNode,onClick:()=>void}) => <div className={className} onClick={onClick}>{children}</div>

  export {GeolocationNeededModal, OneButtonModal, Modal, RedButton, GrayButton, TextModal}