import { useState } from "react"
import { useFullscreen } from "../components/FullscreenPicture"
import { usePhotoInventory } from "./photoinventory"
import { useConfig } from "./useconfig"

const usePhotoFinale = () => {
    const [collectedPhotosCount] = usePhotoInventory(store => store.length)
    const [photoOutroShowed,setPhotoOutroShowed] = useState(false)
    const [_,setFullscreen] = useFullscreen()
    const [config] = useConfig()
  
    //20
    const isReady = collectedPhotosCount == 20 && !photoOutroShowed
  
    const show = () => {
      setPhotoOutroShowed(true)
      config?.photo_outro_movie && setFullscreen({
        src:config.photo_outro_movie, 
        overlayText: config?.photo_inventory_outro_text ?? "", 
        noAutoClose:true,
        overlayOnEnd:true
      })
      return true;
    }
    return {isReady,show}
  }
  
  
  const generateFinaleNumber = () => {
    const r = ()=>Math.floor(1+Math.random() * 8)
    const d = [r(),r(),r()]
    const n = (d[0]*100+d[1]*10+d[2])+""+((d[0]+d[1]+d[2])%9)
    console.log(d,n)
    return "MGW"+n
  }
  
  const useInventoryFinale = () => {
  
    const [outroShowed,setOutroShowed] = useState(false)
    const [visitedPoints, setVisitedPoints] = useState(new Set())
    const [_,setFullscreen] = useFullscreen()
    const [config] = useConfig()
    //const finaleText = (config?.inventory_outro_text ?? "") + "\\n\\n"+"Twój kod ukończenia to\\n"+generateFinaleNumber()+"\\n"+"zapisz go lub zapamiętaj"
    const finaleText = (config?.inventory_outro_text ?? "")
  
    //8
    const isReady = visitedPoints.size === 8 && !outroShowed && config?.inventory_outro_movie
    const collectPoint = (pointId:string) => setVisitedPoints(set => set.add(pointId))
    const show  = () => {
        setOutroShowed(true)
        setFullscreen({
          src:config?.inventory_outro_movie,
          overlayText: finaleText, 
          noAutoClose:true,
          overlayOnEnd:true,
         
          overlayLink:"https://www.youtube.com/embed/8z_Ao0MH9vM"
          //overlayLink:"https://youtu.be/8z_Ao0MH9vM"
        })
    }
    
    return {
      isReady, collectPoint, show
    }
  }

  export {useInventoryFinale,usePhotoFinale}