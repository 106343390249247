
import React, {
    createContext,
    useContext,
    useSyncExternalStore,
  } from "react";
import { readObj, saveObj, deleteObj } from "./saveobj";
  

 function createGlobalStore<Store, ReducerAction>(initialState: Store, reducer : (state:Store,action:ReducerAction) => Store,saveKey?:string) {


    const subscribers = new Set<() => void>()
    const readVal = saveKey ? readObj(saveKey) : undefined

    const store = {
        current : readVal !==null && readVal!==undefined ? readVal as Store : initialState
    }

    function notifySubscribers() {
        subscribers.forEach((callback) => callback());
    }

    const get = () => store.current

  
    const set = (action: ReducerAction) => {
      store.current =  saveKey ? savingReducer(store.current,action) : reducer(store.current,action);
      notifySubscribers()
    }

    const reset = () => {
      store.current = initialState;
      saveKey && (deleteObj(saveKey))
      notifySubscribers()
    }

    const savingReducer = (state:Store,action:ReducerAction) : Store => {
      const newStore = reducer(state,action)
      if(saveKey) {
        saveObj(saveKey,newStore as Object)
      }
      return newStore;
    }

    const subscribe = (callback: () => void) => {
      subscribers.add(callback);
      return () => subscribers.delete(callback);
    }

    const useStoreData = () => ({
        get,
        set,
        subscribe
    })   
  
    type UseStoreDataReturnType = ReturnType<typeof useStoreData>;
  
    const StoreContext = createContext<UseStoreDataReturnType | null>(null);
  
    function Provider({ children }: { children: React.ReactNode }) {
      return (
        <StoreContext.Provider value={useStoreData()}>
          {children}
        </StoreContext.Provider>
      );
    }
  
    function useStore<SelectorOutput>(
      selector: (store: Store) => SelectorOutput
    ): [SelectorOutput, UseStoreDataReturnType["set"]] {
      const store = useContext(StoreContext);
      if (!store) {
        throw new Error("Store not found");
      }
  
      const state = useSyncExternalStore(
        store.subscribe,
        () => selector(store.get()),
        () => selector(initialState),
      );
  
      return [state, store.set];
    }
  
    return {
      Provider,
      useStore,
      get,
      set,
      reset,
      notifySubscribers
    };
  }

  export {createGlobalStore}