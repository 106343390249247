import { useGeo } from "../utils/usegeo";
import { divIcon, latLngBounds, LatLngTuple, Map as MapClass } from 'leaflet';
import {AvailableLangs} from '../utils/dataaccess'
import { OneButtonModal } from "./Modal";
import { useState } from "react";

const isIOS = () => navigator.userAgent.match(/iPhone|iPad/i) !== null
const isAndroid = () => navigator.userAgent.match(/Android/i) !== null
const isMobile = () => isIOS() || isAndroid()



const messages = {
  "ERROR":{
    "PL":(clickHanler:()=>void)=><span>Do prawidłowego działania potrzebujemy<br/>dostępu do informacji o Twojej lokalizacji. <a onClick={clickHanler}>{isMobile() ? "Pomoc" : ""}</a> </span>,
    "EN":(clickHanler:()=>void)=><span>We need geolocalisation data<br/>to show your location on the map <a onClick={clickHanler}>{isMobile() ? "Help" : ""}</a></span>
  },
  "OUUTSIDEMAP":{
    "PL":(clickHanler:()=>void)=><span>Jesteś poza terenem gry. <a onClick={clickHanler}>Przesuń mapę</a></span>,
    "EN":(clickHanler:()=>void)=><span>You're outside of the map. <a onClick={clickHanler}>Pan to the center.</a></span>
  },
  "HELPTITLE":{
    "PL":"Jak włączyć?",
    "EN":"How to enable?"
  },
  "CLOSEBUTTON":{
    "PL":"Zamknij",
    "EN":"Close"
  }
}

const AndroidInstructions = {
  "PL":[
    "Przeciągnij palcem od góry ekranu",
    "Dotknij i przytrzymaj [Lokalizacja]. Jezeli nie widzisz ikonki [Lokalizacja], dotknij [Edycja], [Ustawienia] lub ikonę [+]. Przeciągnij [Lokalizacja] do obszaru szybkich ustawień"
  ],
  "EN":[
    "Swipe down from the top of the screen",
    "Touch and hold Location. If you don't see Location tap Edit (Pencil icon)  or Settings (Cogwheel). Drag Location into your Quick Settings."
  ]
}

const IOSInstructions = {
  "PL":[
    "Otwórz Ustawienia > Prywatność > Usługi Lokalizacji",
    "Upewnij się, ze Usługi Lokalizacji są włączone",
    "Znajdź na liście Safari",
    "Kliknij Safari i wybierz [Podczas uzywania aplikacji] albo [Zawsze]"
  ],
  "EN":[
    "Go to Settings > Privacy > Location Services",
    "Make sure that Location Services is on",
    "Scroll down to find Safari",
    "Tap the app and select [While Using the App] or [Always]"
  ]
}

function GeoHelp({lang,closeFunc}:{lang:AvailableLangs,closeFunc:()=>void}){
  return <OneButtonModal title={messages.HELPTITLE[lang]} buttonTitle={messages.CLOSEBUTTON[lang]} buttonClick={closeFunc} ><GeoHelpContent lang={lang} /></OneButtonModal>
}

function GeoHelpContent({lang}:{lang:AvailableLangs}){
  const list = isAndroid() ? AndroidInstructions[lang] : IOSInstructions[lang]

  return <ol>
    {list.map(t => <li key={t}>{t}</li>)}
  </ol>
}


function GpsStatusBar({bounds,mapbounds,map,lang = "PL"}:{bounds?:L.LatLngBounds | null, mapbounds?:L.LatLngBounds | null, map: MapClass | null,lang?:AvailableLangs}){
  const {geo} = useGeo()
  const moveMapToCenter = ()=>map && bounds && map.panTo(bounds.getCenter())
  const [showHelp, setShowHelp] = useState(false)

  const closeHelp = () => setShowHelp(false)

    if(showHelp)return <GeoHelp lang={lang} closeFunc={closeHelp}></GeoHelp>
    
    if(geo.state=="ERROR")return <div className='YouHaveAMessage'>{messages.ERROR[lang](()=>setShowHelp(true))}</div>
    if(geo.state=="SUCCESS" && bounds && mapbounds && map && !mapbounds.intersects(bounds))return <div className='YouHaveAMessage'>{messages.OUUTSIDEMAP[lang](moveMapToCenter)}</div>
    //console.log('GpsStatusBar doesnt render', geo.state, bounds, mapbounds,  bounds && mapbounds?.intersects(bounds))
    //return <div className='YouHaveAMessage'>Test message</div>
    return null;
  }

  export {GpsStatusBar}