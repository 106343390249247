import Logo from "./Logo";
import {MenuBox, MenuBoxes} from "./MenuBoxes";
import {MenuFooter} from "../FirstPageNew";

const NiceLanguage = () => {
    return <div className="BasicPage">
        <div><Logo></Logo></div>
        <MenuBoxes>
            <MenuBox link={`/mila/pol`}>POL</MenuBox>
            <MenuBox link={`/mila/eng`}>ENG</MenuBox>
            {/*<MenuBox link={`/mila/heb`}>HEB</MenuBox>*/}
        </MenuBoxes>
        <MenuFooter />
    </div>
}

export {NiceLanguage}
