import { Link, useNavigate } from 'react-router-dom';
import './App.css';
import Logo from './components/Logo';
import {reset as resetInventory,get as getInventory} from './utils/inventory'
import {reset as resetPhotos} from './utils/photoinventory'
import {reset as resetInitialData} from './utils/useinitialdata'
import { deleteObj } from './utils/saveobj';
import { MenuBox, MenuBoxContainer, MenuBoxes, MenuBoxRow } from './components/MenuBoxes';

const MenuFooter = () => <div className='menuFooter'>
    <a href='https://1943.pl' target={"_blank"}>Strona www muzeum</a>
    <a href='https://1943.pl/rodo/' target={"_blank"}>RODO</a>
    </div>



const FirstPage = () => {


    return <div className="BasicPage">
        <div><Logo></Logo></div>
        <MenuBoxes>
            <MenuBox link={`/przewodnik`}>Audioprzewodnik PL</MenuBox>
            <MenuBox link={`/przewodnik/en`}>Audioguide EN</MenuBox>
            <MenuBox link={`/mila`}>MIŁA 18 (PL, EN, HEB)</MenuBox>
            <GameStartButtons></GameStartButtons>
            <MenuBox link={`/opowiesc`}>Getto - opowieść heroiczna</MenuBox>
        </MenuBoxes>
        <MenuFooter />
    </div>
}

const GameStartButtons = () => {

    const navigate = useNavigate();
    
    const isGameInProgress = getInventory().length > 0

    const resetGameStorage = () => {
        sessionStorage.clear()
        //resetInventory({type:"reset"})
        //resetPhotos({type:"reset"})
        resetInventory()
        resetPhotos()
        resetInitialData()
        deleteObj('powstanie_visited')
    }

    const resetAndGo = ()=>{resetGameStorage();navigate('/powstanie')}

    return isGameInProgress 
    ? <MenuBoxContainer>
        <h1>Gra terenowa</h1>
        <MenuBoxRow>
            <MenuBox link={`/powstanie`}>Kontynuuj</MenuBox>
            <MenuBox onClick={resetAndGo}>Nowa gra</MenuBox>
        </MenuBoxRow>
    </MenuBoxContainer>
    : <MenuBox onClick={resetAndGo}>Gra terenowa</MenuBox>
    
}


export {FirstPage, MenuFooter}

