import { iGuidePoint } from "../utils/guideDataAccess"
import { useIsMounted } from "../utils/utilhooks"
import { useFullscreen } from "./FullscreenPicture"


const GuidePointCover = ({name,photos}:{name:string,photos: iGuidePoint["photo"]}) => {
    const [_,setFullscreen] = useFullscreen()
    
    photos ?? (photos = [])
    typeof photos == "string" && (photos = [photos])

    return <div className="GuidePointCover">
        <h3>{name}</h3>
        <div className="PhotoList">
        {photos.map(photo => typeof photo == "string" 
            ? <img src={photo} key={photo} onClick={()=>setFullscreen({src:photo, align:"center"})} /> 
            : <img src={photo.picture} key={photo.picture} onClick={()=>setFullscreen({src:photo.picture, align:"center", description:photo.description})} />
        )}
        </div>
    </div>
}

const GuideAudioPlayer = ({mp3}:{mp3?:string}) => {
    return <div className="GuideAudioPlayer">
        <audio controls src={mp3}></audio>
    </div>
}

const GuidePanel = ({point,visible,clickOutsideHandler}:{
    point:Pick<iGuidePoint, "name" | "id" | "photo" | "mp3">
    visible:boolean,
    clickOutsideHandler?:()=>void
}) => {

    const name = point.name
    let isVisible = visible

    let panelRef : any;
    const outsideClickHandler = (clickedEl:any) => {
        if(panelRef !== clickedEl.target) return;
        clickOutsideHandler && clickOutsideHandler()
    }

    //let picture = Array.isArray(point.photo) ? point.photo[0] : point.photo 

    return isVisible ? <div className="GuidePanel" ref={(el:any)=>panelRef = el} onClick={outsideClickHandler}>

        <GuidePointCover name={point.name ?? point.id} photos={point.photo ?? []}></GuidePointCover>
        <GuideAudioPlayer mp3={point.mp3}></GuideAudioPlayer>

    </div> : null;
} 

export {GuidePanel, GuideAudioPlayer}

