
type AvailableLangs = "PL" | "EN"


const availableGames: {[key : string]:iGameData} = {
    "powstanie":{
        "directory":"powstanie",
        "type":""
    },
    "audioprzewodnik":{
        "directory":"audioprzewodnik",
        "type":""
    },
    "opowiesc":{
        "directory":"opowiescheroiczna",
        "type":""
    }
} 

interface iGameData {
    directory:string,
    type:string
}

interface iConfig {
    [key : string] : any
}

async function getConfig(gameName:string, lang:string): Promise<iConfig | null> {
    const filename = await getFilename(gameName,lang,'config.json')

    if(!filename) return null;
    return getJSONfile(filename)
}

async function getGameData(gameName:string){
    return availableGames[gameName] ?? null;
}

async function getFilename(gameName:string, lang:string, name:string, type?:string): Promise<string | null> { 
    const dir = (await getGameData(gameName))?.directory
    if(dir === null) return null;
    return !!type ? `games\\${dir}\\${type}\\${lang}_${name}` : `games\\${dir}\\${lang}_${name}`
}

const globalFileCache  : {[key:string] : Object} = {}

async function getJSONfile(filename:string){
    
    if(globalFileCache[filename]){
        //console.log('FROM CACHE getJSONfile ',filename)
        return globalFileCache[filename];
    } 
    return fetch(filename).then(response => {
        if (!response.ok) {
            throw new Error("HTTP error " + response.status);
        }
        let json = null
        try {
            json = response.json()
            return json;
        } catch (err) {
            return null;
        }
    }).then(json => {
        //console.log('FROM FILE getJSONfile ',filename)
        globalFileCache[filename] = json;
        //console.log(json)
        return json;
    }).catch(err => null)
}

async function getGameJSONfile(gameName:string, lang:string, name:string, type?:string) {
    const filename = await getFilename(gameName,lang,name,type)
    if(filename === null) return null
    return getJSONfile(filename)
}


export {
    getFilename,
    getJSONfile,
    getConfig,
    getGameJSONfile
}

export type {
    iGameData,
    iConfig,
    AvailableLangs
}