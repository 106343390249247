import { useState, ReactNode } from 'react';
import { Link, useParams } from 'react-router-dom';

import Logo from './components/Logo';
import { getGameJSONfile } from './utils/dataaccess';
import { useInitOnce } from './utils/utilhooks';

import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';


import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import './App.css';


import ReactPlayer from 'react-player';
import { MenuBox, MenuBoxes } from './components/MenuBoxes';
import { MenuFooter } from './FirstPageNew';

import triangleicon from './assets/lefttriangleicon.svg'

interface iOpowiescSection {
    name?:string,
    videos?:{
        video?:string,
        caption?:string
    }[]
}

const useLoadedSections = () => {
    const [sections, setSections] = useState<iOpowiescSection[]>([])

    useInitOnce(async ()=>{
        const opowiescData = await getGameJSONfile('opowiesc','PL','opowiesc.json')
        if(opowiescData !== null) setSections(opowiescData as iOpowiescSection[])
        
        console.log(opowiescData)
    })
    return sections;
}

const OpowiescApp = () => {

    const sections = useLoadedSections()

    return <div className="FirstPage">
        <div><Link to="/"><Logo></Logo></Link></div>
        <MenuBoxes>
            <div>
                <h1>Getto - opowieść heroiczna</h1>
            </div>
            {
                sections.map(section => <MenuBox key={section?.name} link={`/opowiesc/`+section?.name}>{section?.name}</MenuBox>)
            }
        </MenuBoxes>
        <div className='sponsorInfo'>Dofinansowano ze środków MKiDN</div>
        <MenuFooter />
    </div>
}


const VideoPlayer = ({onPlay,url,playing,preload}:{onPlay?:()=>void,url?:string,playing?:boolean,preload?:boolean}) => {

    const swiper = useSwiper()
    const onClick = (ev:any)=>{
        const videoel = ev.target
        const playing = !!(videoel.currentTime > 0 && !videoel.paused && !videoel.ended && videoel.readyState > 2);
        console.log(playing)
        playing ? videoel.pause() : videoel.play()
    }
    
    return <ReactPlayer               
              url={url}
              controls={true} 
              width="100%"
              height="calc(var(--vh) - 7vh)"
              playsinline={true}
              preload
            onPlay = {onPlay}
              onBuffer={
                ()=>console.log('buffering..') //tutaj pokazac indykator ladowania
              }
              onBufferEnd={
                ()=>console.log('end buffering') //tutaj schowac indykator ladowania
              }
              onReady={
                ()=>console.log('ready state changed')
              }
              onEnded={
                ()=>{
                    console.log('video ended, switching to next slide') //tutaj przejsc do nastepnego slajdu
                    swiper.slideNext()
                }
              }
              playing={playing}
              config={{file:{attributes:{
                preload:preload ? "auto" : "metadata",
                /* onPointerEnter: onClick */
              }}}}
            />
}


const OpowiescVideoPlayer = () => {
    const {opowiescId} = useParams()
    const sections = useLoadedSections()
    const section = sections.find(section=>section?.name == opowiescId)
    const [isPlaying, setIsPlaying] = useState<string | null>(null);
    const onPlay = (videoUrl?:string) => () => setIsPlaying(videoUrl ?? null);

    // @ts-ignore
    return <><Swiper modules={[Navigation, Pagination]}
        navigation
        pagination={{ clickable: true, dynamicBullets: true, dynamicMainBullets: 10 }}
        className="mySwiper"
        onSlideChange={() => {
          setIsPlaying(null);
        }}
        /* 
        autoplay={false} 
        watchSlidesProgress={true} 
        */
      >
        {section?.videos?.map((data,index) => (
          <SwiperSlide key={data?.video}>
            <VideoPlayer 
                onPlay={onPlay(data?.video)} 
                url={data?.video} 
                playing={isPlaying == data?.video}
                preload={
                    true 
                    || index === 0
                    || isPlaying == data?.video
                }
            ></VideoPlayer>
          </SwiperSlide>
        ))}
        
      </Swiper>
      <div className='BottomBar'>
        <div style={{fontWeight:"bold"}}><Link to={'/opowiesc'}><img src={triangleicon} style={{height:"0.7em"}}></img> {opowiescId}</Link></div><Link to={'/'}><Logo></Logo></Link>
      </div>
      </>
    
    /*
    <div className='FirstPage'>
        {opowiescId}
        {
            section?.videos?.map(item => <div>{item?.video}</div>)
        }
    </div>
    */
}

export {OpowiescApp, OpowiescVideoPlayer}
