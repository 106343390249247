import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import ChatApp from './ChatApp';
import AudioGuideApp from './GuideApp';
import {register} from './serviceWorkerRegistration'

import {
  createHashRouter,
  RouterProvider,
} from "react-router-dom";


import { FullscreenProvider } from './components/FullscreenPicture';
import { FirstPage } from './FirstPageNew';
import { GeoProvider } from './utils/usegeo';
import { OpowiescApp, OpowiescVideoPlayer } from './OpowiescApp';
import {NiceLanguage} from "./components/NiceLanguage";
import {NiceTime} from "./components/NiceTime";
import {Nice} from "./components/Nice";


const router = createHashRouter([
  {path:'/', element: <FirstPage/> },
  {path:'/powstanie', element: <ChatApp/>},
  {path:'/przewodnik', element: <AudioGuideApp lang="PL"/>},
  {path:'/przewodnik/pl', element: <AudioGuideApp lang="PL"/>},
  {path:'/przewodnik/en', element: <AudioGuideApp lang="EN"/>},
  {path:'/opowiesc', element: <OpowiescApp/>},
  {path:'/opowiesc/:opowiescId', element: <OpowiescVideoPlayer/>},
  {path:'/mila', element: <NiceLanguage/>},
  {path:'/mila/:language', element: <NiceTime/>},
  {path:'/mila/:language/:time', element: <Nice/>},
])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

//<RouterProvider router={router}></RouterProvider>
root.render(
  <React.StrictMode>
    <GeoProvider>
    <FullscreenProvider>
    <RouterProvider router={router}></RouterProvider>
    </FullscreenProvider>
    </GeoProvider>
  </React.StrictMode>
);

register()

//  <AudioGuideApp />
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
